import { toast } from "react-toastify";
import { login_using_refresh_token } from "../app/modules/Auth/_redux/authCrud";
import * as auth from "../app/modules/Auth/_redux/authRedux";
import Swal from "sweetalert2";

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      } else {
        config.headers.businessid = 23;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );
  axios.interceptors.response.use(
    (response) => {
      if (
        response.data.message &&
        response.data.message === "Lead created successfully."
      ) {
        toast.success("Successful!A program advisor would contact you soon!");
      } else {
        response.data?.message &&
          Swal.fire({
            icon: "success",
            html: ["/event", "/events"].includes(window.location.pathname)
              ? "Spot reserved successfully"
              : response.data.message,
          });
      }

      return response.data.data;
    },
    async (error) => {
      let {
        auth: { refreshToken },
      } = store.getState();
      const originalRequest = error.config;
      if (
        error?.response?.status === 403 &&
        refreshToken &&
        !originalRequest._retry
      ) {
        try {
          originalRequest._retry = true;
          const splashScreen = document.getElementById("splash-screen");
          if (splashScreen) splashScreen.style.display = "";
          const response = await login_using_refresh_token({
            refresh_token: refreshToken,
          });
          if (splashScreen) splashScreen.style.display = "none";
          //we have token in this response

          //save this token
          store.dispatch(
            auth.actions.login(
              response.token,
              response.refresh_token,
              response.user
            )
          );
          return axios(originalRequest);
        } catch (error) {
          store.dispatch(auth.actions.logout());
        }
      } else if (error?.response?.status === 401) {
        store.dispatch(auth.actions.logout());
      } else {
        throw error;
      }
    }
  );
}
